<template>
  <div class="card p-2">
    <div class="col-12 col-lg-11 col-xxl-10">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-5">
          <div class="mb-1 row">
            <label for="colFormLabel" class="col-3 col-sm-3 col-lg-12 col-xl-3 col-form-label">
              Module
            </label>
            <div class="col-9 col-sm-9 col-lg-12 col-xl-9">
              <v-select
                  placeholder="Select Module"
                  v-model="moduleId"
                  :options="modules"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-5">
          <div class="mb-1 row">
            <label for="colFormLabel" class="col-3 col-sm-3 col-lg-12 col-xl-3 col-form-label">
              Role
            </label>
            <div class="col-9 col-sm-9 col-lg-12 col-xl-9">
              <v-select
                  placeholder="Select Role"
                  v-model="roleId"
                  :options="roles"
                  label="label"
                  :reduce="name => name.id"
              />
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-end">
          <button
              style="min-width: 64px;"
              @click="getPagePermissions"
              class="btn btn-primary btn-block waves-effect waves-float waves-light mb-1"
          >Go
          </button>
        </div>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th width="20%"> Menu Design</th>
        <th></th>
        <th class="text-center">Action</th>
      </tr>
      </thead>

      <tbody>
      <template v-for="(menu, i) in menus" :key="i">
        <tr>
          <td> {{ menu.name }}</td>
          <td></td>
          <td>
          </td>
        </tr>

        <template v-for="(subMenu, j) in menu.submenus" :key="j">
          <tr v-if="!menu.notIncluded">
            <td></td>
            <td> {{ subMenu.name }}</td>
            <td>
            </td>
          </tr>
          <template v-for="(page, k) in subMenu.pages" :key="k">
            <tr>
              <td></td>
              <td></td>
              <td>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group mb-2">
                      {{ page.name }}
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-check mb-1 bulk-child">
                      <input type="checkbox" class="form-check-input" v-model="page.create" :id="`${j}-${k}-create`"
                             value="1">
                      <label class="form-check-label" :for="`${j}-${k}-create`">create</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-check mb-1 bulk-child">
                      <input type="checkbox" class="form-check-input" v-model="page.update" :id="`${j}-${k}-update`"
                             value="1">
                      <label class="form-check-label" :for="`${j}-${k}-update`">update</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-check mb-1 bulk-child">
                      <input type="checkbox" class="form-check-input" v-model="page.read" :id="`${j}-${k}-read`"
                             value="1">
                      <label class="form-check-label" :for="`${j}-${k}-read`">read</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-check mb-1 bulk-child">
                      <input type="checkbox" class="form-check-input" v-model="page.delete" :id="`${j}-${k}-delete`"
                             value="1">
                      <label class="form-check-label" :for="`${j}-${k}-delete`">delete</label>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </template>
      </tbody>
    </table>
    <button @click="saveUpdatePagePermissions()" class="btn btn-success w-25">Save/Update</button>
    <Loader v-if="loading"/>
  </div>
</template>

<script>
import Loader from '@/components/atom/LoaderComponent'
import {mapGetters, useStore} from 'vuex'
import {computed, inject} from 'vue'
import handleRole from "@/services/modules/role";
import handleRolePermission from "@/services/modules/role-permission";

export default {
  name: 'RolePermission',

  components: {
    Loader
  },

  data: () => ({
    roles: [],
    roleId: null,
    menus: {},
    companies: [],
    moduleId: null,
    loading: false,
  }),

  computed: {
    ...mapGetters({
      modules: 'company/modules'
    }),

    companyId() {
      return this.$route.params.companyId
    }
  },

  methods: {
    getRoleList() {
      this.roleList(this.companyId)
          .then(res => {
            if (res.status) this.roles = res.data
          })
    },
    async getPagePermissions() {
      let query = `?company_id=${this.companyId}&module_id=${this.moduleId}&role_id=${this.roleId}`
      try {
        this.loading = true
        let res = await this.fetchPagePermissions(query)
        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.menus = res.data
          await this.$router.push({
            name: 'role-permission',
            params: {companyId: this.companyId, moduleId: this.moduleId, roleId: this.roleId}
          })
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
    saveUpdatePagePermissions() {
      this.loading = true
      let query = {
        menus: this.menus,
        company_id: this.companyId,
        role_id: this.roleId,
        module_id: this.moduleId
      }
      this.storeUpdatePagePermissions(query)
          .then(res => {
            if (!res.status) this.showError(res.message)
            if (res.status) {
              this.showSuccess(res.message)
              this.getPagePermissions()
            }
            this.loading = false
          })
          .catch(err => {
            this.loading = false
          })
    }
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const store = useStore()

    const {roleList} = handleRole()
    const {fetchPagePermissions, storeUpdatePagePermissions} = handleRolePermission()
    return {
      fetchPagePermissions,
      storeUpdatePagePermissions,
      roleList,
      modules: computed(() => store.state.company.modules),
      showError,
      showSuccess
    }
  },

  created() {
    this.moduleId = parseInt(this.$route.params.moduleId)
    this.roleId = parseInt(this.$route.params.roleId)
  },
  mounted() {
    this.getRoleList()
    this.getPagePermissions()
  }
}
</script>
